import { CHEVRON } from "@components/atoms/Icons"
import { Logo, LogoMark } from "@components/atoms/Logo"
import { Menu } from "@components/atoms/Menu"
import { Footer } from "@components/site/Footer"
import { Header } from "@components/site/Header"
import { Link, ScrollRestoration, useRoute } from "eddev/routing"
import { defineView } from "eddev/views"
import { AnimatePresence, motion } from "framer-motion"
import { useNav } from "@hooks/useNav"
import { useRandomProjects } from "@hooks/queries"
import { PersonPopout } from "@blocks/people/people-list"
import { usePersonPopout } from "@hooks/usePersonPopout"
import { useEffect, useMemo } from "react"
import { ProjectFilter } from "@components/atoms/ProjectFilter"
import { ProjectFragment } from "../types.graphql"

export default defineView("_app", (props) => {
  const route = useRoute()
  const navStatus = useNav()

  function useRouteThemeClass() {
    switch (route.view) {
      case "front-page": {
        return "subtheme-projects"
      }

      case "templates/company": {
        return "subtheme-company"
      }

      case "single-career": {
        return "subtheme-company"
      }

      case "templates/projects": {
        return "subtheme-projects"
      }

      case "single-project": {
        return "subtheme-projects"
      }

      case "templates/services": {
        return "subtheme-services"
      }

      case "templates/contact": {
        return "subtheme-contact"
      }

      case "templates/thinking": {
        return "subtheme-thinking"
      }

      case "single": {
        return "subtheme-thinking"
      }

      case "templates/search": {
        return "subtheme-projects"
      }

      case "category": {
        return "subtheme-thinking"
      }

      default: {
        return "subtheme-projects"
      }
    }
  }

  const themeClass = useRouteThemeClass()

  const variants = {
    show: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        delay: 0.2,
      },
    },
    hide: {
      y: 10,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
  }

  const smoothScroll = (element: HTMLDivElement, duration = 600) => {
    const startY = window.scrollY
    const targetY = element.getBoundingClientRect().top + window.scrollY - (80 + 37)
    const distance = targetY - startY
    const startTime = performance.now()

    const easeInOutQuad = (t: number) => {
      return t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2
    }

    const animateScroll = (currentTime: number) => {
      const timeElapsed = currentTime - startTime
      const progress = Math.min(timeElapsed / duration, 1) // progress between 0 and 1
      const easing = easeInOutQuad(progress)

      window.scrollTo(0, startY + distance * easing)

      if (timeElapsed < duration) {
        requestAnimationFrame(animateScroll)
      }
    }

    requestAnimationFrame(animateScroll)
  }

  useEffect(() => {
    if (!route.query.anchor) return

    const element = document.getElementById(route.query.anchor as string) as HTMLDivElement
    if (!element) return

    smoothScroll(element)
  }, [route.query])

  useEffect(() => {
    if (!route.hash) return

    const element = document.getElementById(route.hash.replace("#", "") as string) as HTMLDivElement
    if (!element) return

    smoothScroll(element)
  }, [route.hash])

  const randomProjects = useRandomProjects(undefined)

  const projects = useMemo(() => {
    return (randomProjects.data?.randomProjects ?? []) as ProjectFragment[]
  }, [randomProjects.data?.randomProjects])

  return (
    <div className={themeClass}>
      <ScrollRestoration />
      <Header />
      <div className={`min-h-screen flex flex-col justify-between pt-headerHeight md:pt-0`}>
        <AnimatePresence mode="wait">
          {route.view !== "front-page" ? (
            <div className="w-full flex">
              <MenuWrapper />
              <div
                className={`${navStatus.open ? `w-contentOpened max-w-contentOpened` : `w-contentClosed max-w-contentClosed`} md:px-6 transition-all ease-in-out duration-500`}
              >
                <motion.div
                  variants={variants}
                  initial={route.key === "single-project" ? "show" : "hide"}
                  animate="show"
                  exit="hide"
                  key={route.id}
                  className="h-full"
                >
                  {props.children}
                </motion.div>
              </div>
            </div>
          ) : (
            <>
              <motion.div
                variants={variants}
                initial="hide"
                animate="show"
                exit="hide"
                key={route.id}
                className="h-full"
              >
                {props.children}
                <div className="w-full flex">
                  <MenuWrapper />

                  <div
                    className={`${navStatus.open ? `w-contentOpened max-w-contentOpened` : `w-contentClosed max-w-contentClosed`} md:px-6 transition-all ease-in-out duration-500 `}
                  >
                    {projects.length > 0 && (
                      <div className="w-full">
                        <h2 className="px-5 md:px-0 py-7 md:pt-5 md:pb-[100px] bg-orange md:bg-grey type-title-l-light md:type-title-xxl-light w-full">
                          Projects
                        </h2>
                        <ProjectFilter projects={projects} />
                      </div>
                    )}
                  </div>
                </div>
              </motion.div>
            </>
          )}
        </AnimatePresence>
        <Footer />
      </div>
    </div>
  )
})

const MenuWrapper = () => {
  const navStatus = useNav()
  const personPopout = usePersonPopout()

  return (
    <>
      <div
        className={`hidden md:flex h-dvh min-h-dvh transition-all ease-in-out duration-500 sticky top-0 z-[70] overflow-hidden ${navStatus.open ? `w-menuOpened min-w-menuOpened` : `w-menuClosed min-w-menuClosed`}`}
      >
        <div className="w-menuOpened min-w-menuOpened h-full">
          <div className="bg-hero transition-all ease-in-out duration-500 w-full h-full flex flex-col">
            <div className={`flex relative flex-col justify-between grow transition-all ease-in-out duration-500`}>
              <div className="flex flex-col">
                <Link href="/" className="relative">
                  <span
                    className={`flex w-[192px] justify-start h-[32px] p-4 box-content transition-all ease-in-out duration-500 ${navStatus.open ? `opacity-100` : `opacity-0`}`}
                  >
                    <Logo />
                  </span>
                  <span
                    className={`flex w-[26px] justify-start h-[32px] p-4 box-content absolute left-0 top-0 transition-all ease-in-out duration-500 ${navStatus.open ? `opacity-0` : `opacity-100`}`}
                  >
                    <LogoMark />
                  </span>
                </Link>
                <div
                  className={`menu-main transition-all ease-in-out duration-500 border-t ${navStatus.open ? `opacity-100` : `opacity-0 pointer-events-none`}`}
                >
                  <Menu location="Main" />
                </div>
              </div>

              <div
                className={`menu-secondary transition-all ease-in-out duration-500 ${navStatus.open ? `opacity-100` : `opacity-0 pointer-events-none`}`}
              >
                <Menu location="Secondary" />
              </div>
            </div>

            <button
              className="absolute h-full top-[65px] right-0 w-menuClosed flex justify-center items-center xl:hidden"
              onClick={() => navStatus.setOpen(!navStatus.open)}
            >
              <i className={`block ${navStatus.open ? `rotate-180` : `rotate-0`}`}>{CHEVRON}</i>
            </button>
          </div>
        </div>
      </div>
      <PersonPopout />
    </>
  )
}
