import { createUseQuery } from "eddev/hooks";
import {
  UseAdditionalProjectsQuery,
  UseFooterBlockQuery,
  UseNotStickyArticlesQuery,
  UsePageQuery,
  UsePageQueryVariables,
  UsePersonQuery,
  UsePersonQueryVariables,
  UseProjectCategoriesQuery,
  UseProjectsQuery,
  UseProjectsQueryVariables,
  UseRandomProjectsQuery,
  UseSearchMediaQuery,
  UseSiteSearchQuery,
  UseSiteSearchQueryVariables,
  UseStickyArticlesQuery,
  UseTaglineQuery,
} from "../types.graphql";

/**
 * DO NOT EDIT
 * This file is automatically generated by eddev
 * Any changes will be overwritten
 */

export const useAdditionalProjects = createUseQuery<UseAdditionalProjectsQuery, undefined>({
  name: "UseAdditionalProjects",
});

export const useFooterBlock = createUseQuery<UseFooterBlockQuery, undefined>({ name: "UseFooterBlock" });

export const useNotStickyArticles = createUseQuery<UseNotStickyArticlesQuery, undefined>({
  name: "UseNotStickyArticles",
});

export const usePage = createUseQuery<UsePageQuery, UsePageQueryVariables>({ name: "UsePage" });

export const usePerson = createUseQuery<UsePersonQuery, UsePersonQueryVariables>({ name: "UsePerson" });

export const useProjectCategories = createUseQuery<UseProjectCategoriesQuery, undefined>({
  name: "UseProjectCategories",
});

export const useProjects = createUseQuery<UseProjectsQuery, UseProjectsQueryVariables>({ name: "UseProjects" });

export const useRandomProjects = createUseQuery<UseRandomProjectsQuery, undefined>({ name: "UseRandomProjects" });

export const useSearchMedia = createUseQuery<UseSearchMediaQuery, undefined>({ name: "UseSearchMedia" });

export const useSiteSearch = createUseQuery<UseSiteSearchQuery, UseSiteSearchQueryVariables>({ name: "UseSiteSearch" });

export const useStickyArticles = createUseQuery<UseStickyArticlesQuery, undefined>({ name: "UseStickyArticles" });

export const useTagline = createUseQuery<UseTaglineQuery, undefined>({ name: "UseTagline" });
