import { Chip, ManualChip } from "@components/atoms/Chips"
import { ResponsiveImage } from "@components/atoms/ResponsiveImage"
import { ProjectFragment, TermFragment } from "../../types.graphql"
import "leaflet/dist/leaflet.css"
import { useRouter } from "eddev/routing"
import { useMemo, useRef, useState } from "react"
import { AnimatePresence, motion } from "framer-motion"
import { Button } from "@components/atoms/Button"
import { useBreakpoint } from "@hooks/useBreakpoint"
import { useNav } from "@hooks/useNav"
import { Share } from "@components/atoms/Share"
import { useProjectCategories } from "@hooks/queries"

export const ProjectFilter = ({ projects }: { projects: ProjectFragment[] }) => {
  const [filters, setFilters] = useState<string[]>([])

  const filteredProjects = useMemo(() => {
    if (projects) {
      if (filters.length > 0) {
        return projects.filter((project) =>
          // @ts-ignore
          project.terms?.nodes.some((term) => filters.includes(term.name!)),
        )
      }
      return projects
    }
    return null
  }, [filters, projects])

  const [filterOpen, setFilterOpen] = useState<boolean>(false)
  const projectCategories = useProjectCategories(undefined)

  return (
    <>
      {projectCategories.data?.projectCategories?.nodes && (
        <>
          <div className={`grid-auto ${env.admin ? `w-full` : `oversize sticky top-headerHeight md:top-0 z-10`}`}>
            <div className="group w-full flex flex-col col-span-12 grid-auto">
              <div
                className="grid-auto md:hidden sticky top-headerHeight col-span-12 border-y md:border-b-0 py-3 px-5 md:px-6 w-full bg-orange md:bg-grey"
                onClick={() => setFilterOpen(!filterOpen)}
              >
                <h5 className="col-span-1 type-ui-xs-caps flex items-center">Filters</h5>
                <div className="flex col-span-11 flex-wrap gap-3 inner-orange">
                  {filters.length === 0 ? (
                    <>
                      <ManualChip title="All filters" />
                    </>
                  ) : (
                    <>
                      <ManualChip title={`${filters.length} filter${filters.length > 1 ? `s` : ``} selected`} />
                    </>
                  )}
                </div>
              </div>

              <div
                className={`grid-auto md:hidden col-span-12 px-5 w-full bg-grey h-auto overflow-hidden ${filterOpen ? `border-b border-black max-h-[400px]` : `border-grey max-h-0`} transition-all ease-in duration-200 cursor-pointer`}
              >
                <div className="col-span-11 col-start-2 flex flex-wrap gap-3 py-4">
                  {projectCategories.data.projectCategories.nodes?.map((cat, i) => (
                    <ManualChip
                      hideBullet={!filters.includes(cat.name!)}
                      title={cat.name!}
                      key={i}
                      onClick={() => {
                        setFilters((prevFilters) => {
                          if (prevFilters.includes(cat.name!)) {
                            return prevFilters.filter((filter) => filter !== cat.name)
                          } else {
                            return [...prevFilters, cat.name!]
                          }
                        })
                      }}
                      active={filters.includes(cat.name!)}
                    />
                  ))}
                </div>
              </div>

              <div className="hidden md:grid-auto w-full max-w-full col-span-12 border-t py-3 px-6 bg-grey">
                <h5 className="col-span-1 type-ui-xs-caps flex items-center">Filters</h5>

                <div className="flex col-span-11 flex-wrap gap-3 md-inner-grey">
                  {filters.length === 0 ? (
                    <>
                      <ManualChip title="All filters" />
                    </>
                  ) : (
                    <>
                      {projectCategories.data.projectCategories.nodes
                        .filter((cat) => filters.includes(cat.name!))
                        .map((cat, i) => (
                          <ManualChip
                            title={cat.name!}
                            key={i}
                            onClick={() => {
                              setFilters((prevFilters) => {
                                if (prevFilters.includes(cat.name!)) {
                                  return prevFilters.filter((filter) => filter !== cat.name)
                                } else {
                                  return [...prevFilters, cat.name!]
                                }
                              })
                            }}
                          />
                        ))}
                    </>
                  )}
                </div>
              </div>

              <div className="hidden md:grid-auto max-w-full col-span-12 px-6 w-full bg-grey h-auto max-h-0 overflow-hidden border-black border-b group-hover:h-auto group-hover:max-h-10 transition-all ease-in duration-200 cursor-pointer">
                <div className="flex col-span-11 flex-wrap gap-3 md-inner-grey col-start-2 pb-7 pt-3">
                  {projectCategories.data.projectCategories.nodes
                    .filter((cat) => !filters.includes(cat.name!))
                    .map((cat, i) => (
                      <ManualChip
                        hideBullet
                        title={cat.name!}
                        key={i}
                        onClick={() => {
                          setFilters((prevFilters) => {
                            if (prevFilters.includes(cat.name!)) {
                              return prevFilters.filter((filter) => filter !== cat.name)
                            } else {
                              return [...prevFilters, cat.name!]
                            }
                          })
                        }}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>

          <div className={`grid-auto ${env.admin ? `w-full` : `oversize`}`}>
            {filteredProjects && (
              <div className="col-span-12 flex flex-col">
                {filteredProjects.map((project, i) => (
                  <ProjectCard project={project} key={i} />
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}

export const ProjectCard = ({ project }: { project: ProjectFragment }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const isMd = useBreakpoint("md")
  const router = useRouter()
  const navStatus = useNav()

  const smoothScroll = (element: HTMLDivElement, uri: string, duration = 600) => {
    const startY = window.scrollY
    const targetY = isMd
      ? element.getBoundingClientRect().top + window.scrollY - 37.9
      : element.getBoundingClientRect().top + window.scrollY - 80
    const distance = targetY - startY
    const startTime = performance.now()

    const easeInOutQuad = (t: number) => {
      return t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2
    }

    const animateScroll = (currentTime: number) => {
      const timeElapsed = currentTime - startTime
      const progress = Math.min(timeElapsed / duration, 1) // progress between 0 and 1
      const easing = easeInOutQuad(progress)

      window.scrollTo(0, startY + distance * easing)

      if (timeElapsed < duration) {
        requestAnimationFrame(animateScroll)
      } else {
        router.navigate(uri)
      }
    }

    requestAnimationFrame(animateScroll)
  }

  const transition = (uri: string | null) => {
    if (containerRef.current && uri) {
      // scroll to top minus the back bar height
      smoothScroll(containerRef.current, uri)
      // set height to 100vh
      containerRef.current.classList.add("h-screen", "!max-h-screen")
      const orangeContainer = containerRef.current.querySelector(".changeOrange")
      if (orangeContainer) orangeContainer.classList.add("bg-orange")
      const termList = containerRef.current.querySelector(".md-inner-grey")
      if (termList) termList.classList.add("inner-orange")
    }
  }

  return (
    <AnimatePresence>
      <motion.div
        ref={containerRef}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="flex flex-col w-full border-b relative transition-all ease-in-out duration-500 md:max-h-[40vh] md:hover:max-h-[60vh] overflow-hidden"
      >
        <div className="absolute z-10 w-full h-full block" onClick={() => transition(project.uri)} />
        <div className="changeOrange w-full py-5 md:bg-transparent transition-all ease-in-out duration-500">
          <div
            className={`grid-auto ${env.admin ? `max-w-[1200px] w-full items-start px-6 m-0` : `items-center max-w-full`}`}
          >
            <div className="col-span-12 flex flex-col justify-start pb-5 md:px-6">
              <h1 className="type-title-xl-medium" dangerouslySetInnerHTML={{ __html: project?.title ?? "" }} />
              <h2 className="type-title-xl-light" dangerouslySetInnerHTML={{ __html: project?.subtitle ?? "" }} />
            </div>

            <div className="col-span-12 flex justify-between mt-10 md:mt-0 gap-3 md:px-6">
              <div className="flex flex-wrap gap-3 md-inner-grey items-center">
                {project?.terms?.nodes.map((term, i) => <Chip key={i} {...(term as TermFragment)} />)}
              </div>
            </div>
          </div>
        </div>

        <div className={`md:grid-auto items-center md:max-w-full md:px-6`}>
          <div className="col-span-12 flex flex-col gap-4">
            <ResponsiveImage aspect={16 / 9} {...project.featuredImage?.node} />
            <div className="type-ui-xs-caps px-5 md:px-0" dangerouslySetInnerHTML={{ __html: project.caption ?? "" }} />
          </div>
        </div>

        <div className={`grid-auto items-center max-w-full md:px-6`}>
          <div className="col-span-12">
            <p
              className={`type-title-s-light md:type-title-m-light my-6 md:max-w-maxText`}
              dangerouslySetInnerHTML={{ __html: project?.intro ?? "" }}
            />
          </div>

          <div className={`col-span-12 hidden md:block py-4`}>
            <Share title={project?.title ?? ""} />
          </div>
        </div>

        <div className="flex flex-col px-5 md:px-6 py-4 gap-5 md:hidden">
          <Button>Read more</Button>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}
