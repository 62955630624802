import { Button } from "./Button"
import { useEffect, useState } from "react"
import { EMAIL, FACEBOOK, LINK, LINKEDIN, X } from "./Icons"
import { useRoute } from "eddev/routing"
import { useNav } from "@hooks/useNav"

type shareProps = {
  facebook: string
  linkedin: string
  twitter: string
  email: string
}

export const Share = ({ title }: { title: string }) => {
  const [open, setOpen] = useState<boolean>(false)
  const [copiedLink, setCopiedLink] = useState<boolean>(false)
  const [shareLinks, setShareLinks] = useState<shareProps | null>(null)
  const route = useRoute()
  const navStatus = useNav()

  useEffect(() => {
    if (route.uri) {
      setShareLinks({
        facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(window.location.href)}`,
        linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(window.location.href)}`,
        twitter: `https://twitter.com/intent/tweet?url=${encodeURI(window.location.href)}`,
        email: `mailto:?body=${encodeURI(window.location.href)}&subject=${title}`,
      })
    }
  }, [route])

  async function copyLink() {
    await navigator.clipboard.writeText(window.location.href)
    setCopiedLink(true)
  }

  return (
    <div className="flex flex-col items-start relative w-full md:w-auto">
      <Button onClick={() => setOpen(!open)} className="md:w-[140px] bg-grey">
        Share
      </Button>
      <div
        className={`overflow-hidden transition-all ease-in-out duration-500 absolute top-full pt-2 right-0 z-10 w-full md:w-full ${open ? `max-h-screen overflow-auto` : `max-h-0`}`}
      >
        {shareLinks && (
          <ul className="flex flex-col gap-2 w-full md:w-[150px]">
            <li>
              <Button href={shareLinks.facebook} className="!bg-grey hover:!bg-black" target="_blank">
                <>{FACEBOOK} Facebook</>
              </Button>
            </li>
            <li>
              <Button href={shareLinks.linkedin} className="!bg-grey hover:!bg-black" target="_blank">
                <>{LINKEDIN} LinkedIn</>
              </Button>
            </li>
            <li>
              <Button href={shareLinks.twitter} className="!bg-grey hover:!bg-black" target="_blank">
                <>{X} X</>
              </Button>
            </li>
            <li>
              <Button href={shareLinks.email} className="!bg-grey hover:!bg-black" target="_blank">
                <>{EMAIL} Email</>
              </Button>
            </li>
            <li>
              <Button onClick={copyLink} className="!bg-grey hover:!bg-black !w-full">
                <>
                  {LINK} {copiedLink ? `Link copied to clipboard` : `Copy link`}
                </>
              </Button>
            </li>
          </ul>
        )}
      </div>
    </div>
  )
}
