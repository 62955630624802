import { Link } from "eddev/routing"
import { TermFragment } from "../../types.graphql"
import { BULLET } from "./Icons"

export const Chip = (props: TermFragment) => {
  return (
    <Link
      className="flex justify-center items-center py-2 md:py-1 px-3 bevel-chip text-black gap-1 transition ease-linear duration-200 hover:text-white"
      href={props.uri}
    >
      {BULLET} <span>{props.name}</span>
    </Link>
  )
}

export const ManualChip = ({
  title,
  link,
  onClick,
  active,
  hideBullet,
  hideBorder,
}: {
  title: string
  link?: string
  onClick?: () => void
  active?: boolean
  hideBullet?: boolean
  hideBorder?: boolean
}) => {
  if (link)
    return (
      <Link
        className={`flex justify-center items-center py-2 md:py-1 px-3 text-black gap-1 transition ease-linear duration-200 ${hideBorder ? `hide-border` : `bevel-chip hover:text-white`}`}
        href={link}
      >
        {!hideBullet && <>{BULLET} </>}
        <span>{title}</span>
      </Link>
    )

  return (
    <div
      className={`flex justify-center items-center py-2 md:py-1 px-3 text-black gap-1 transition ease-linear duration-200 ${onClick ? `cursor-pointer` : ``} ${active ? `active` : ``} ${hideBorder ? `hide-border` : `bevel-chip hover:text-white`}`}
      onClick={onClick ?? undefined}
    >
      {!hideBullet && <>{BULLET} </>}
      <span>{title}</span>
    </div>
  )
}
